/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Image, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="mt--30 pb--0 pt--0" name={"kontakt"}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--60 pl--0 pr--0 pt--50 flex--top" anim={null} animS={null} style={{"backgroundColor":"var(--color-custom-1)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left fs--62 title-box--invert" content={"Kontakt"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" anim={null} animS={null} style={{"maxWidth":510}}>
              
              <Subtitle className="subtitle-box subtitle-box--invert lh--16" content={"info@mytiokencz.cz"}>
              </Subtitle>

              <Text className="text-box text-box--invert" content={"IČ: 652 01 621"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim5 --anim-s3 pb--80 pt--80" anim={"5"} name={"kontakt-2"} animS={"3"}>
          
          <ColumnWrap className="column__flex el--2 pb--06 pl--02 pr--02 pt--06 flex--top" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":520}}>
              
              <Title className="title-box" content={"Kontaktní formulář"}>
              </Title>

              <Image style={{"maxWidth":348}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/39397/caadf7d073a9465784662d6e45d89b46_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/caadf7d073a9465784662d6e45d89b46_s=660x_.jpg 660w"} alt={""} src={"https://cdn.swbpg.com/t/39397/caadf7d073a9465784662d6e45d89b46_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape2 --style1 --right fs--18 lh--2" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"36haan82pvgj"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail","id":"b82u0npnkusn"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"m6l4l8wg41yz"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy","id":"p3udhlccy9f5"},{"name":"Odeslat","type":"submit","align":"left","id":"rcwbabtyjxxa"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--0" name={"lw60z5h5vx"}>
        </Column>


        <Column className="js-anim  --anim2 pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-custom-1)"}} anim={"2"}>
          
          <ColumnWrap className="column__flex el--2 mb--20 mt--10 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left fs--24 title-box--invert" style={{"maxWidth":""}} content={"Pojďte do toho s námi!"}>
              </Title>

              <Text className="text-box text-box--invert" content={"<span style=\"font-weight: bold;\">Důsledná kontrola\n<br>Férové ceny\n<br>Flexibilita\n<br>Pečlivost</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}